import React, { useState, useEffect } from "react";
import "./bookDetails.css"; // Import your CSS file
import * as bookServices from "../../services/book.services";
import { useParams } from "react-router-dom";
import { ACCESS_TOKEN, BASE_URL, CLIENT_REF } from "../../config/app.config";
import Header from "../../components/header";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Link } from "react-router-dom";
const API_URL = `${BASE_URL}books`;
const BookDetails = () => {
  const [book, setBook] = useState({});
  const bookId = useParams().bookId;
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedBookId, setEditedBookId] = useState(null);
  const [editedBookTitle, setEditedBookTitle] = useState(null);
  const [editedBookDescription, setEditedBookDescription] = useState(null);
  const [editBookLanguage, setEditBookLanguage] = useState(null);
  const [editBookPublishYear, setEditBookPublishYear] = useState(null);
  const [digitalEditionPageNumber, setDigitalEditionPageNumber] = useState(null);

  const [addPageModalOpen, setAddPageModalOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(null);
    const [targetBook, setTargetBook] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const [mediaModalOpen, setMediaModalOpen] = useState(false);
    const [audioFile, setAudioFile] = useState([]);
    const [videoFile, setVideoFile] = useState([]);
    const [PDFFile, setPDFFile] = useState(null);
    const [digitalEdition, setDigitalEdition] = useState(null);

    const [voiceName, setVoiceName] = useState('');
    const [videoName, setVideoName] = useState('');
    const [mediaNameModal, setMediaNameModal] = useState(false);

    const [currentPages, setCurrentPages] = useState([]);

    const [addBookThumbnail, setAddBookThumbnail] = useState(null);

  const safeDecodeURI = (uri) => {
    if (!uri) return '';
    try {
      return decodeURIComponent(uri.split('/').pop().replace(/\+/g, " "));
    } catch (e) {
      console.warn('Failed to decode URI:', e);
      return uri;
    }
  };

  const handleEdit = (book) => {
    console.log(book);
    setEditModalOpen(true);
    setEditedBookId(book._id);
    setEditedBookTitle(book.title);
    setEditedBookDescription(book.description);
    setEditBookLanguage(book.language);
    setPDFFile(book.sample);
    setAddBookThumbnail(book.thumbnail);
    setDigitalEdition(book.digitalEdition);
    setEditBookPublishYear(book.publishYear);
    setDigitalEditionPageNumber(book.digitalEditionPageNumber);
}

const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setEditedBookId(null);
    setEditedBookTitle(null);
    setEditedBookDescription(null);
}
const handleSaveEdit = () => {
    bookServices.editBook(editedBookId, editedBookTitle, editedBookDescription, editBookLanguage, PDFFile, undefined, addBookThumbnail, digitalEdition, digitalEditionPageNumber).then((res) => {
        alert('Book updated successfully');
        window.location.reload();
    }).catch((err) => {
        console.log(err);
    })
}

const handleAddPage = (id) => {
  setAddPageModalOpen(true);
  setTargetBook(id);
}



const handleFileUpload = async (e, action) => {
  setIsUploading(true);
  if (e.target.name === 'audio') {
     await bookServices.uploadFile(bookId, e.target.files[0], 'audio').then((res) => {
      if(res.data != null){
      setAudioFile((current) => [...current, {
        name: null,
        url: res.data
      }]);
      setIsUploading(false);
    }
    else{
      alert('Error uploading file');
    }
    }).catch((err) => {
      alert('Error uploading file');
      console.log(err);
    })
  } else if (e.target.name === 'video') {
      await bookServices.uploadFile(bookId, e.target.files[0], 'video').then((res) => {
      if(res.data != null){
      setVideoFile((current) => [...current, {
        name: null,
        url: res.data
      }]);
      setIsUploading(false);
      }
      else{
        alert('Error uploading file');
      }
    }).catch((err) => {
      alert('Error uploading file');
      console.log(err);
    })
  }
  else if (e.target.name === 'pdf' && action === 'sample') {
    await bookServices.uploadFile(bookId, e.target.files[0], 'pdf-sample').then((res) => {
    if(res.data != null){
    setPDFFile(res.data);
    setIsUploading(false);
    }
    else{
      alert('Error uploading file');
    }
    }).catch((err) => {
      alert('Error uploading file');
      console.log(err);
    })
  }
  else if (e.target.name === 'pdf' && action === 'digital') {
    await bookServices.uploadFile(bookId, e.target.files[0], 'pdf-sample').then((res) => {
    if(res.data != null){
    setDigitalEdition(res.data);
    setIsUploading(false);
    }
    else{
      alert('Error uploading file');
    }
    }).catch((err) => {
      alert('Error uploading file');
      console.log(err);
    })
  }
}

const handleThumbnailUpload = async (e) => {
  setIsUploading(true);
  const maxFileSize = 5 * 1024 * 1024; // 5MB
  const fileData = e.target.files[0];
  const allowedExtensions = ['png', 'jpeg', 'jpg'];
  const fileExtension = fileData.name.split('.').pop().toLowerCase();
  if (!allowedExtensions.includes(fileExtension)) {
    alert('Invalid file format. Please choose a PNG or JPEG file.');
    e.target.value = null; // Clear the input
    setIsUploading(false);
    return;
  }
  if (fileData && fileData.size > maxFileSize) {
    // File size exceeds the limit, handle accordingly
    alert('File size exceeds the limit. Please choose a smaller file.');
    // Optionally, clear the input
    e.target.value = null;
    setIsUploading(false);
  } else {
    const file = await bookServices.uploadFile(bookId, e.target.files[0], 'image')
    setAddBookThumbnail(file.data)
    setIsUploading(false);
  }

}
const handleSaveAddPage = () => {
  if(currentPages.includes(Number(pageNumber))){
    alert('Page number already exists');
  }
  else{
  bookServices.addPage(bookId, pageNumber, audioFile, videoFile).then((res) => {
      alert('Page added successfully');
      setAddPageModalOpen(false);
      setPageNumber(null);
      setTargetBook(null);
      window.location.reload();
  }).catch((err) => {
      console.log(err);
  })}
}


const handleUpdatePageMedia = () => {
  bookServices.addPage(bookId, pageNumber, audioFile, videoFile, 'addMedia').then((res) => {
      alert('Media added successfully');
      setAddPageModalOpen(false);
      setPageNumber(null);
      setTargetBook(null);
      window.location.reload();
  }).catch((err) => {
      console.log(err);
  })
}

const handleUpdateMediaName = () => {
  bookServices.addPage(bookId, pageNumber, audioFile, videoFile, 'updateMediaName').then((res) => {
      alert('Media name updated successfully');
      setMediaNameModal(false);
      setPageNumber(null);
      setTargetBook(null);
      window.location.reload();
  }).catch((err) => {
      console.log(err);
  })
}

const handleClosePageModal = () => {
  setAddPageModalOpen(false);
  setPageNumber(null);
}

const handleAddMedia = (selectedPageNumber,voiceFiles,videoFiles) => {
  setMediaModalOpen(true);
  setPageNumber(selectedPageNumber);
  setAudioFile(voiceFiles ? voiceFiles : []);
  setVideoFile(videoFiles ? videoFiles : []);
}

const handleEditMediaName = (selectedPageNumber, voiceFiles, videoFiles) => {
  setPageNumber(selectedPageNumber);
  setMediaNameModal(true);
  setAudioFile(voiceFiles ? voiceFiles : []);
  setVideoFile(videoFiles ? videoFiles : []);
}

const handleEditMediaAudio = (value, index) => {
  setAudioFile((current) => {
    current[index].name = value;
    return current;
  });
}

const handleEditMediaVideo = (value, index) => {
  setVideoFile((current) => {
    current[index].name = value;
    return current;
  });
}
  

const handleCloseMediaModal = () => {
  setMediaModalOpen(false);
  setPageNumber(null);
  setTargetBook(null);
  setAudioFile([]);
  setVideoFile([]);
}

const handleCloseMediaNameModal = () => {
  setMediaNameModal(false);
  setPageNumber(null);
}



  useEffect(() => {
    bookServices
      .getBookById(bookId)
      .then((res) => {
        res.data.pages.map((page) => {
          setCurrentPages(current => [...current, page.number])
        })
        setBook(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDeleteBookPage = (id, pageId) => {
    bookServices.deleteBookPage(id, pageId).then((res) => {
      console.log(res)
      alert('Page deleted successfully');
      window.location.reload();
  }
  ).catch((err) => {
      console.log(err);
  })
  }

  return (
    <>
      <Header />
      <div className="book-details-container">
        <div className="book-details">
          <div className="book-details__image">
            <img src={book?.image} alt="" />
          </div>
          <div className="book-details__content">
            <h1>{book?.title}</h1>
            <p>{book?.description}</p>
          </div>
          <div className="book-actions">
            <Button className="edit-book-btn" onClick={() => handleEdit(book)}>
              Edit
            </Button>
            <Button
              className="add-page-btn"
              onClick={() => handleAddPage(book?._id)}
            >
              Add Page
            </Button>
            {/* to be sheet upload  */}
            <Link to={`${book._id}/${book.codeBase}`}>
              <Button className="book-codes-btn">Book Codes</Button>
            </Link>
            <Link to="/books">
              <Button className="back-books-btn">Back to books</Button>
            </Link>
          </div>
          <Dialog
            open={editModalOpen}
            onClose={handleCloseEditModal}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Edit Book</DialogTitle>
            <DialogContent>
              <label className="edit-book-label">Book Name</label>
              <input
                autoFocus
                type="text"
                placeholder="Book Title"
                value={editedBookTitle}
                onChange={(e) => setEditedBookTitle(e.target.value)}
                className="edit-book-name"
              />
              <label className="edit-book-label">Book Description</label>
              <textarea
                type="text"
                placeholder="Description"
                value={editedBookDescription}
                onChange={(e) => setEditedBookDescription(e.target.value)}
                className="edit-book-description"
              />
              <label className="edit-book-label">Book Language</label>
              <select
                type="text"
                placeholder="Language"
                value={editBookLanguage}
                onChange={(e) => setEditBookLanguage(e.target.value)}
                className="edit-book-language"
              >
                {["English", "Turkish", "Arabic", "German", "French"].map(
                  (language, index) => (
                    <option key={index} value={language}>
                      {language}
                    </option>
                  )
                )}
              </select>
              <label className="edit-book-label">Book Publish Year</label>
              <input
                autoFocus
                type="text"
                placeholder="Book Publish Year"
                value={editBookPublishYear}
                onChange={(e) => setEditBookPublishYear(e.target.value)}
                className="edit-book-name"
              />
              <label className="voice-file-label">Book smaple</label>
              <input
                type="file"
                name="pdf"
                className="voice-file-uploader"
                onChange={(e) => handleFileUpload(e, 'sample')}
              />
              <p>{`Current sample: ${safeDecodeURI(book?.sample)}`}</p>
              <label className="voice-file-label">Digital Edition</label>
              <input
                type="file"
                name="pdf"
                className="voice-file-uploader"
                onChange={(e) => handleFileUpload(e, 'digital')}
              />
              <label className="edit-book-label">Digital Edition Page Number</label>
              <input
                autoFocus
                type="number"
                placeholder="Digital Edition Page Number"
                value={digitalEditionPageNumber}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  const minValue = book?.pages?.length || 1; // Default to 1 if book.pages.length is undefined
                  setDigitalEditionPageNumber(value < minValue ? minValue : value);
                }}
                className="edit-book-name"
                min={book?.pages?.length}
                required={(book?.digitalEdition || digitalEdition) ? true : false}
              />
                <small style={{ color: 'gray' }}>
                  Min value will always stick to defined page numbers, which is {book?.pages?.length}.
                </small>
              <p>{`Current digital edition: ${safeDecodeURI(book?.digitalEdition)}`}</p>
              <label className="voice-file-label">Book Thumbnail</label>
              <input
                type="file"
                name="image"
                className="voice-file-uploader"
                onChange={(e) => handleThumbnailUpload(e)}
              />
              <p>{`Current thumbnail: ${safeDecodeURI(book?.thumbnail)}`}</p>
              <p className="instructions">
                File size should not exceed 5 MB, also this form only accepts
                PNG/JPEG/JPG files
              </p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditModal} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSaveEdit} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={addPageModalOpen}
            onClose={handleClosePageModal}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Add Book Pages</DialogTitle>
            <DialogContent>
              <label className="edit-book-label">Page Number</label>
              <input
                autoFocus
                type="number"
                placeholder="Page Number"
                value={pageNumber}
                onChange={(e) =>
                  setPageNumber(e.target.value <= 0 ? 1 : e.target.value)
                }
                className="edit-book-name"
                min={1}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePageModal} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSaveAddPage} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={mediaModalOpen}
            onClose={handleCloseMediaModal}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Add Book Media</DialogTitle>
            <DialogContent>
              <label className="voice-file-label">Voice file</label>
              <input
                type="file"
                name="audio"
                className="voice-file-uploader"
                onChange={(e) => handleFileUpload(e, 'voice')}
              />
              <label className="video-file-label">Video file</label>
              <input
                type="file"
                name="video"
                className="video-file-uploader"
                onChange={(e) => handleFileUpload(e, 'video')}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMediaModal} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpdatePageMedia} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {isUploading ? (
            <Dialog open={isUploading} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Uploading...</DialogTitle>
              <DialogContent>
                <p>Please wait while the file is being uploaded</p>
              </DialogContent>
            </Dialog>
          ) : (
            <Dialog
              open={mediaNameModal}
              onClose={handleCloseMediaNameModal}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Edit Media Name</DialogTitle>
              <DialogContent>
                {audioFile?.map((file, index) => {
                  return (
                    <>
                      <div>
                        <label className="voice-file-label">{`Voice File ${index + 1}`}</label>
                        <p>
                        Old Name:{" "}
                          {file.name ? file.name : safeDecodeURI(file.url)}
                        </p>
                        <label>New Name</label>
                        <input
                          type="text"
                          className="file-name-input"
                          onChange={(e) =>
                            handleEditMediaAudio(e.target.value, index)
                          }
                        />
                      </div>
                      <hr />
                      <br />
                    </>
                  );
                })}
                {videoFile?.map((file, index) => {
                  return (
                    <>
                      <div>
                        <label className="video-file-label">{`Video File ${index + 1}`}</label>
                        <p>
                          Old Name:{" "}
                          {file.name ? file.name : safeDecodeURI(file.url)}
                        </p>
                        <label>New Name</label>
                        <input
                          type="text"
                          className="file-name-input"
                          onChange={(e) =>
                            handleEditMediaVideo(e.target.value, index)
                          }
                        />
                      </div>
                      <hr />
                      <br />
                    </>
                  );
                })}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseMediaNameModal} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleUpdateMediaName} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        <div className="book-details__table">
          <TableContainer className="admin-table-container" component={Paper}>
            <Table className="admin-table">
              <TableHead>
                <TableRow className="table-header-row">
                  <TableCell className="table-cell" width="5%">
                    Page Number
                  </TableCell>
                  <TableCell className="table-cell" width="20%">
                    Voice File
                  </TableCell>
                  <TableCell className="table-cell" width="20%">
                    Video File
                  </TableCell>
                  <TableCell className="table-cell" width="35%">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {book?.pages?.map((page) => (
                  <TableRow key={page.number}>
                    <TableCell
                      className="table-cell"
                      component="th"
                      scope="row"
                      width="5%"
                    >
                      {page.number}
                    </TableCell>
                    <TableCell className="table-cell" width="20%">
                      {page.voiceFiles?.map((file, index) => (
                        <p key={index}>                          
                          {file.name ? file.name : safeDecodeURI(file.url)}
                        </p>
                      ))}
                    </TableCell>
                    <TableCell className="table-cell" width="20%">
                      {page.videoFiles?.map((file, index) => (
                        <p key={index}>                          
                          {file.name ? file.name : safeDecodeURI(file.url)}
                        </p>
                      ))}
                    </TableCell>
                    <TableCell className="table-cell" width="35%">
                      <Button
                        className="delete-button"
                        onClick={() =>
                          handleAddMedia(
                            page.number,
                            page.voiceFiles,
                            page.videoFiles
                          )
                        }
                      >
                        Add Media
                      </Button>
                      <Button
                        className="delete-button"
                        onClick={() => handleDeleteBookPage(book._id, page._id)}
                      >
                        Delete Page
                      </Button>
                      <Button
                        className="delete-button"
                        onClick={() =>
                          handleEditMediaName(
                            page.number,
                            page.voiceFiles,
                            page.videoFiles
                          )
                        }
                      >
                        Edit Media Name
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default BookDetails;
